<template>
  <div class="container">
    <div class="row center-xs middle-xs login-page">
      <div class="col-xs-12 wrapper text-left">
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <h1>{{ $t('store.title') }}</h1>
          </div>
          <div class="col-xs-12 col-md-6 user-balance" v-if="isAuth">
            <h2><router-link to="refill" :title="this.$t('store.refill')">{{ $t('store.balance') }}: {{ $tc('store.credites', userBalance) }}</router-link></h2>
          </div>
        </div>
        <div class="row products-list">

          <div
            class="product col-xs-12 col-sm-6 col-lg-3"
            :class="['col-lg-' + pr.colspan, pr.colspan > 3 ? 'high' : '']"
            v-for="pr in products"
            :key="'product_' + pr.id">
            <div :class=" {'product-wrapper': true, 'product-wrapper-package': (isPackage(pr) || (!isPackage(pr) && pr.desc)) }">
              <div class="image">
                <img :src="getImage(pr.img)" :alt="pr.name">
              </div>
              <div class="desc-wrapper">
                <div class="desc" v-if="isPackage(pr)">
                  <p class="before-list">{{ $t('store.packageHas') }}</p>
                  <ul>
                    <li
                      v-for="(it, i) in pr.items"
                      :key="'item_' + pr.id + '_' + i"
                    >{{ $t('store.' + it.type_name) }} {{ it.count }} {{ $t('store.' + it.count_type) }}</li>
                  </ul>
                </div>
                <div class="desc" v-if="!isPackage(pr) && pr.desc">
                  <p>{{ pr.desc }}</p>
                </div>
              </div>
              <div class="product-footer">
                <div class="title">
                  <p>{{ pr.name }}</p>
                </div>
                <div class="price">
                  {{ $tc('store.credites', pr.cost) }} &asymp; {{ pr.cost_local }} {{ pr.cur_local }}
                </div>
                <div class="btns">
                  <button class="btn" @click="buyHandler(pr.id)">{{ $t('store.buy') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      name="success-buy"
      @opened="modalOpened"
      @beforeClose="beforeClose">
      <div class="modal-content-wrapper">
        <div class="success-checkmark">
          <div class="check-icon" ref="checkIcon">
            <span class="icon-line line-tip"></span>
            <span class="icon-line line-long"></span>
            <div class="icon-circle"></div>
            <div class="icon-fix"></div>
          </div>
        </div>
        <div class="modal-title">{{ $t('store.modalSuccessTitle') }}</div>
        <div class="modal-desc" v-html="this.$t('store.modalSuccessDesc')"></div>
        <button 
          class="btn"
          @click="closeModal">{{ $t('store.modalSuccessBtn') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import { getProducts, buyProduct } from '../lib/api'
import types from '@/types'

export default {
  name: 'StoreIndex',
  data: () => {
    return {
      products: []
    }
  },
  async created() {
    this.products = await this.loadProducts()
  },
  mounted() {
    //return this.$modal.show('success-buy')
  },
  methods: {
    modalOpened() {
      this.$refs.checkIcon.classList.add('show')
    },
    beforeClose() {
      this.$refs.checkIcon.classList.remove('show')
    },
    closeModal() {
      this.$modal.hide('success-buy')
    },
    async loadProducts() {
      const {data: res} = await getProducts()
      
      if (res.status !== 1) {
        console.error(res.msg)
        return
      }

      return res.data
    },
    async buyHandler(productId) {
      if (!this.isAuth) {
        window.location.href = `/login?return=${window.encodeURIComponent('/store')}`
        return;
      }

      const { data: res } = await buyProduct({ token: this.userToken, productId })

      if (res.status !== 1) {
        switch (res.errorid) {
          case 8:
            this.$notify({ type: 'error', text: this.$t('store.balanceIsNotEnough') })
            break;
          case 13:
            this.$notify({ type: 'error', text: this.$t('store.needConfirmPnumber') })
            break;
          default: 
            this.$notify({ type: 'error', text: res.errormsg })
        }

        return
      }
      this.$store.dispatch(types.CHECK_TOKEN)
      return this.$modal.show('success-buy')
    },
    getImage(prImage) {
      return prImage || "https://cdn.warforgalaxy.com/images/store/product_placeholder.png";
    },
    isPackage(pr) {
      return pr.items && pr.items.length > 1
    }
  },
  computed: {
    userToken() {
      return this.$store.state.user.token
    },
    userBalance() {
      return this.$store.state.user.credites
    },
    isAuth() {
      return this.$store.getters.isAuth
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h1, h2, h2 a {
  color: #EE873D;
}

.login-page {
  margin-top: 100px;
}

.user-balance {
  text-align: right;

  a {
    text-decoration: none;
    font-size: 1.2rem;
    border-bottom: 1px dashed #EE873D

  }
}

.modal-content-wrapper {
  min-height: 100%;
  color: #383838;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .success-checkmark {
    //height: 80px;
  }

  a {
    color: #383838;
    text-decoration: underline;
  }

  .modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }

  .modal-desc {
    margin-bottom: 10px;
  }
}

.check-icon {
  display: none;

  &.show {
    display: block;
  }
}

.product {
  padding: 1px;
  color: #b4bdbc;
  position: relative;

  .image {
    // position: absolute;
    // top: 0;
    // left: 0;
    // padding: 10px 20px;
    z-index: 1;
    width: 100%;
    text-align: center;

    img {
      //max-height: 165px;
      display: inline-block;
    }
  }

  .product-wrapper {
    border: 1px solid transparent;
    //border-radius: 3px;
    padding: 20px;
    //height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 10;

    &:after {
      content: '';
      display: none;
      //background:
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.75);
      z-index: 20;
    }
    //transition: all 0.3s eas;
  }

  // &.high {
  //   .image {
  //     //img { max-height: 250px; }
  //   }

  //   .product-wrapper {
  //     //height: 380px;
  //   }
  // }

  .desc-wrapper {
    position: absolute;
    top: 20px;
    left: 20px;
    width: calc(100% - 40px);
    visibility: hidden;
    z-index: 30;

    .desc {
      position: absolute;
      top: 0;
      left: 0;

      p {
        font-size: 12px;
      }

      .before-list {
        text-transform: uppercase;
        font-weight: bold;
      }

      ul {
        padding: 0;
        margin: 5px 0;

        li {
          list-style: none;
        }
      }
    }
  }
  
  .btns {
    visibility: hidden;
    z-index: 30;
    position: relative;
    
    .btn {
      padding: 10px 10px;
      width: 100%;
      cursor: pointer;
      text-transform: uppercase;
    }
  }

  .price {
    color: #EE873D;
    font-weight: bold;
    margin-bottom: 10px;
    z-index: 30;
    position: relative;
  }

  .product-footer {
    z-index: 30;
    position: relative;
  }

  &:hover {
    
    .product-wrapper {
      border-color: rgb(65, 65, 65);
      z-index: 10;

      &.product-wrapper-package {
        &:after {
          display: block;
        }
      }
      
      .desc-wrapper {
        visibility: visible;
      }
    }

    .btns {
      visibility: visible;
    }

    .image {
      img {
        //opacity: 0.5;
      }
    }
  }
}
</style>
