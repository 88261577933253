const config = {
    API_URL: 'http://localhost:3000',
    LS_TOKEN_NAME: 'glxtoken',
    RETURN_TOKEN_NAME: 'glxreturn',
    RECAPTCHA_PUBLIC: "6Lde7qoZAAAAAD9ZeVLJkcK6z-v76fpSjBP6Vhx5",
    ymCounterId: 70912753
}

if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
    config['API_URL'] = 'https://api.warforgalaxy.com'
}

config['oauth'] = {
    vk: {
        url: 'https://oauth.vk.com/authorize',
        q: {
            'client_id': 7586568,
            'display': 'page',
            'redirect_uri': config.API_URL + '/user/social-auth?provider=vk',
            'scope': 'email',
            'response_type': 'code',
            'v': '5.122'
        }
    },
    google: {
        url: 'https://accounts.google.com/o/oauth2/auth',
        q: {
            'client_id': '786066697962-he23g878gu9rdvg3lu3og2ntar7bk6fe.apps.googleusercontent.com',
            'redirect_uri': config.API_URL + '/user/social-auth?provider=google',
            'scope': 'profile',
            'response_type': 'code'
        }
    },
    fb: {
        url: 'https://www.facebook.com/dialog/oauth',
        q: {
            'client_id': '1223799104652627',
            'redirect_uri': config.API_URL + '/user/social-auth?provider=fb',
            'scope': 'public_profile',
            'display': 'page',
            'response_type': 'code'
        }
    }
}

export default config
