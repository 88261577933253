<template>
  <div class="row center-xs middle-xs login-page">
    <div class="col-xs-12 col-md-10 col-lg-6 wrapper">
      <!-- <div class="change-lang">
        <select @change="changeLocale">
          <option value="ru">RU</option>
          <option value="en">EN</option>
        </select>
      </div> -->
      <h1>{{ $t("notFoundPage.title") }}</h1>
      <div>
        {{ $t("notFoundPage.text") }}
      </div>
    </div>
  </div>
</template>

<script>
import { login } from '../lib/api'
import { fdToObj, saveToLS } from '../lib/utils'
import config from '../config'

export default {
  name: 'NotFoundPage',
  data: () => {
    return {
    }
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.login-page {
  margin-top: 150px;
}
.wrapper {
  background: rgb(72,82,83);
  background: linear-gradient(90deg, rgba(72,82,83,1) 41%, rgba(108,116,117,0) 100%);
  border: 1px solid #717E81;
  padding: 80px 50px 20px 50px;
  position: relative;

  &:after {
    width: 97px;
    height: 118px;
    position: absolute;
    top: -48px;
    left: 50%;
    margin-left: -49px;
    background: url("~@/assets/login_logo.png") no-repeat;
    background-size: contain;
    display: block;
    content: '';
  }
}

.change-lang {
  position: absolute;
  right: 10px;
  top: 10px;

  select {
    color: #FFF;
    background: #191919;
    padding: 3px 5px
  }
}

h1 {
  font-family: "Russo One", Arial, Helvetica, sans-serif;
  font-size: 1.7rem;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1px;
  margin-bottom: 30px;
}

form {
  margin-bottom: 15px;
}
p {
  margin-bottom: 15px;
  display: block;
}
p.before {
  margin-top: 0px;
  margin-bottom: 16px;
}

form button {
  margin-left: 2px;
}

.soc-btns {
  cursor: pointer;

  a {
    text-decoration: none;
  }
}

.soc-btn {
  padding: 21px;
  padding-left: 80px;
  margin-bottom: 16px;
  position: relative;
  box-shadow: 0px 0px 5px #000;

  &:before {
    box-shadow: 0px 0px 5px #000;
    content: '';
    display: block;
    width: 63px;
    height: 63px;
    top: 0;
    left: 0;
    position: absolute;
  }

  &-fb {
    background: #5374BA;

    &:before {      
      background: url("~@/assets/fb.png") no-repeat;
    }
  }

  &-google {
    background: #659AF3;

    &:before {
      background: url("~@/assets/google.png") no-repeat;
    }
  }

  &-vk {
    background: #527DAE;

    &:before {
      background: url("~@/assets/vk.png") no-repeat;
    }
  }

  span {
    font-weight: bold;
  }
}

.success {
  padding: 10px;
  background: green;
  color: white;
  border-radius: 3px;
  margin-bottom: 1rem;
}
.input-group {
  position: relative;

  input {
    padding-right: 3rem;
  }
  
  span {
    display: block;
    position: absolute;
    top: 1rem;
    right: 10px;
    font-size: 1.5rem;
    cursor: pointer;
    user-select: none;
    
    img {
      display: inline-block;
      height: 1.5rem;
      vertical-align: middle;
    }
  }
}
</style>
