<template>
  <div class="row center-xs middle-xs recovery-page">
    <div class="col-xs-12 col-md-10 col-lg-6 wrapper">
      <!-- <div class="change-lang">
        <select @change="changeLocale">
          <option value="ru">RU</option>
          <option value="en">EN</option>
        </select>
      </div> -->
      <h1>{{ $t("profilePage.user") }} &laquo;{{ username }}&raquo;</h1>
      <!-- <h2>{{ $t("profilePage.credites") }} {{ credites }} {{ $t("profilePage.pcs") }} <a href="/">(Пополнить)</a></h2> -->
      <div class="row">
        <div class="col-xs-12 col-md-6 text-left">
          <h3 class="block-title">Изменение пароля</h3>
          <form @submit.prevent="submitHandlerPassword">

            <input class="input" type="password" name="password" v-model="password" :placeholder="this.$t('updatePassPage.pass')" minlength="8" required>
            <input class="input" type="password" name="confirm_password" v-model="confirmPassword" :placeholder="this.$t('updatePassPage.rptPass')" minlength="8" required>
            <div class="row success-msg" v-if="successResultPassword">
              <div class="col-xs-12">
                <p>Для подтверждения смены пароля, перейдите по ссылке в, отправленном на ваш email, письме.</p>
              </div>
            </div>
            <div class="row error-msg" v-if="errorPassword">
              <div class="col-xs-12">
                <p>{{ errorPassword }}</p>
              </div>
            </div>
            <button class="btn" type="submit">{{ $t("updatePassPage.submitbtn") }}</button>
          </form>
        </div>
        <div class="col-xs-12 col-md-6 text-left">
          <div class="row">
            <div class="col-xs-12">
              <h3 class="block-title">Изменение почты</h3>
              <form @submit.prevent="submitHandlerEmail">
                <input class="input" type="email" name="email" :value="email" required>
                <div class="row success-msg" v-if="successResultEmail">
                  <div class="col-xs-12">
                    <p>Для окончательной привязки нового email к аккаунту вам необходимо пройти по ссылке в письме, которое отправлено на новый email</p>
                  </div>
                </div>
                <div class="row error-msg" v-if="errorEmail">
                  <div class="col-xs-12">
                    <p>{{ errorEmail }}</p>
                  </div>
                </div>
                
                <button class="btn" type="submit">{{ $t("updatePassPage.submitbtn") }}</button>
              </form>
            </div>
            <!-- <div class="col-xs-12">
              <h3 class="block-title">Изменение номера телефона</h3>
              <form @submit.prevent="confirmPhone">
                <p v-if="pnumber">Текущий привязанный номер: +{{ pnumber }}</p>
                <input class="input" type="text" v-model="pnumberVerification.pnumber" pattern="^\+\d{6,}$" placeholder="+7XXXXXXXXXX" required>
                <div v-if="pnumberVerification.isSentCode">
                  <p v-if="!pnumberVerification.smsTimerExpired">Сейчас на ваш номер телефона поступит звонок. Введите 4 последнии цифр этого номера ниже. Код верификации будет действителен в течении <Countdown v-if="pnumberVerification.smsExpireAt" :end="pnumberVerification.smsExpireAt" @onTimerExpire="sendCodeExpireHandler"/></p>
                  <p v-else>Старый код верификации недействителен <a href="#" @click.prevent="sendCode">Отправить снова</a></p>
                  <input type="hidden" name="taskid" v-model="pnumberVerification.taskid">
                  <input class="input" type="text" v-model="pnumberVerification.code" pattern="^\d+$" minlength="4" maxlength="4" placeholder="4 последнии цифр номера">
                </div>
                <div class="row error-msg" v-if="errorPhoneConfirm">
                  <div class="col-xs-12">
                    <p>{{ errorPhoneConfirm }}</p>
                  </div>
                </div>
                <button class="btn" type="submit">Привязать номер</button>
              </form>
            </div> -->
            <div class="col-xs-12">
              <h3 class="block-title">Изменение привязанного Telegram аккаунта</h3>
              <form @submit.prevent="confirmTg">
                <p v-if="tgnick">Текущий привязанный аккаунт: {{ tgnick }}</p>
                <input class="input" type="text" v-model="tgVerification.tgnick" pattern="^[0-9a-zA-Z_]{3,}$" placeholder="XXXXXXXXXX без @" required>
                <div v-if="tgVerification.isSentCode">
                  <p v-if="!tgVerification.smsTimerExpired">Сейчас вам придет сообщение. Введите код из сообщения ниже. Код верификации будет действителен в течении <Countdown v-if="tgVerification.smsExpireAt" :end="tgVerification.smsExpireAt" @onTimerExpire="sendTgCodeExpireHandler"/></p>
                  <p v-else>Старый код верификации недействителен <a href="#" @click.prevent="sendTgCode">Отправить снова</a></p>
                  <input type="hidden" name="taskid" v-model="tgVerification.taskid">
                  <input class="input" type="text" v-model="tgVerification.code" pattern="^\d+$" minlength="4" maxlength="4" placeholder="4-значный код">
                </div>
                <div class="row error-msg" v-if="errorTgConfirm">
                  <div class="col-xs-12">
                    <p>{{ errorTgConfirm }}</p>
                  </div>
                </div>
                <button class="btn" type="submit">Привязать аккаунт</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-6 text-left">
          <button class="btn" @click="inVacation()">Уйти в отпуск (14 дней)</button>&nbsp;
          <button class="btn" @click="logoutDevices()" :disabled="isLogoutProcess">Выйти на других устройствах</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { changePass, changeEmail, revokeToken, sendCode, verifyCode, inVacation, sendTgCode, verifyTgCode } from '../lib/api'
import { fdToObj } from '../lib/utils'
import types from '../types'
import Countdown from '../components/countdown'

export default {
  name: 'ProfilePage',
  components: {
    Countdown
  },
  data: () => {
    return {
      errorPassword: '',
      errorEmail: '',
      errorPhoneConfirm: '',
      errorTgConfirm: '',
      password: '',
      confirmPassword: '',
      successResultPassword: false,
      successResultEmail: false,
      isLogoutProcess: false,
      pnumberVerification: {
        pnumber: '',
        code: '',
        taskid: null,
        isSentCode: false,
        smsExpireAt: 0,
        smsTimerExpired: false
      },
      tgVerification: {
        tgnick: '',
        code: '',
        taskid: null,
        isSentCode: false,
        smsExpireAt: 0,
        smsTimerExpired: false
      }
    }
  },
  computed: {
    username() {
      return this.$store.state.user.name
    },
    email() {
      return this.$store.state.user.email
    },
    credites() {
      return this.$store.state.user.credites
    },
    userToken() {
      return this.$store.state.user.token
    },
    pnumber() {
      return this.$store.state.user.pnumber
    },
    tgnick() {
      return this.$store.state.user.tgnick
    }
    // pnumber: {
    //   get: function () {
    //     if (this.pnumberVerification.pnumber) {
    //       return this.pnumberVerification.pnumber
    //     } else if (this.$store.state.user.pnumber) {
    //       return '+' + this.$store.state.user.pnumber
    //     } else {
    //       return ''
    //     }
    //   },
    //   set: function (v) {
    //     this.pnumberVerification.pnumber = v
    //   }
    // }
  },
  methods: {
    async logoutDevices() {
      this.isLogoutProcess = true

      try {
        await revokeToken({ token: this.userToken })
      } catch (err) {
        console.error(err)
      }

      this.isLogoutProcess = false
    },
    checkPasswords() {
      if (this.password && this.confirmPassword && this.password === this.confirmPassword) {
        return true
      }
      
      return false
    },
    clearAfter(v, newv = '', ms = 1500) {
      setTimeout(() => {
        this[v] = newv
      }, ms)
    },
    async submitHandlerPassword(ev) {
      this.errorPassword = ''
      const form = ev.target

      if (!this.checkPasswords()) {
        this.errorPassword = "Пароли не совпадают"
        this.clearAfter('errorPassword', '', 3000)
        return false
      }

      const formObj = fdToObj(new FormData(form))

      formObj['token'] = this.userToken

      const { data } = await changePass(formObj)
      if (data.status !== 1) {
        this.setErrorMsg(data.errorid, 'errorPassword')
        return;
      }

      form.reset()

      this.successResultPassword = true
      this.clearAfter('successResultPassword', false, 3000)
    },
    async submitHandlerEmail(ev) {
      this.errorEmail = ''
      const form = ev.target

      // TODO: checking that new email is not equal the old email

      const formObj = fdToObj(new FormData(form))
      formObj['token'] = this.userToken

      const { data } = await changeEmail(formObj)
      if (data.status !== 1) {
        this.setErrorMsg(data.errorid, 'errorEmail')
        return;
      }

      form.reset()

      this.successResultEmail = true
      this.clearAfter('successResultEmail', false, 5000)
    },
    setErrorMsg(errorid, target) {
      let errorMsg = ''

      switch (errorid) {
        case 4:
          return window.location.href = '/login'
        case 9:
          errorMsg = 'Слишком частые попытки подтверждить. Попробуйте чуть позже'
          break;
        case 10:
          errorMsg = 'Неверный код верификации. Проверьте что ввели правильный код'
          break;
        case 25:
          errorMsg = 'Неверный код верификации. Проверьте что ввели правильный код'
          break;
        case 11:
          errorMsg = 'Не найдет данный процесс привязки номера. Обновите страницу и пробуйте заново'
          break;
        case 26:
          errorMsg = 'Не найдет данный процесс привязки аккаунта. Обновите страницу и пробуйте заново'
          break;
        case 12:
          errorMsg = 'Похоже этот номер уже привязан к вашему аккаунту'
          break;
        case 24:
          errorMsg = 'Похоже этот аккаунт уже привязан к вашему аккаунту'
          break;
        default:
          errorMsg = "Техническая ошибка. Проверьте что данные введены корректно и попробуйте снова"
      }

      this[target] = errorMsg
      this.clearAfter(target, '', 3000)
    },
    async confirmPhone() {
      

      if (this.pnumberVerification.isSentCode === false) {
        // send code
        this.sendCode()
        
      } else {
        // verify code
        this.verifyCode()
      }
    },
    async confirmTg() {
      if (this.tgVerification.isSentCode === false) {
        this.sendTgCode()
      } else {
        this.verifyTgCode()
      }
    },
    async sendCode() {
      const token = this.userToken
      const pnumber = this.pnumberVerification.pnumber.replace(/[^0-9]+/g, '')

      const { data: response } = await sendCode({ token, pnumber })

      if (response.status !== 1) {
        return this.setErrorMsg(response.errorid, 'errorPhoneConfirm')
      }

      this.pnumberVerification.smsTimerExpired = false
      this.pnumberVerification.taskid = response.data.task_id
      this.pnumberVerification.smsExpireAt = response.data.expireAfterMs
      this.pnumberVerification.isSentCode = true
    },
    async sendTgCode() {
      const token = this.userToken
      const tgnick = this.tgVerification.tgnick.replace(/[^0-9a-zA-Z_]+/g, '')

      const { data: response } = await sendTgCode({ token, tgnick })

      if (response.status !== 1) {
        return this.setErrorMsg(response.errorid, 'errorTgConfirm')
      }

      this.tgVerification.smsTimerExpired = false
      this.tgVerification.taskid = response.data.task_id
      this.tgVerification.smsExpireAt = response.data.expireAfterMs
      this.tgVerification.isSentCode = true
    },
    async verifyCode() {
      const token = this.userToken
      const pnumber = this.pnumberVerification.pnumber.replace(/[^0-9]+/g, '')
      const taskid = this.pnumberVerification.taskid
      const code = this.pnumberVerification.code

      const { data: response } = await verifyCode({ token, pnumber, taskid, code })

      if (response.status !== 1) {
        return this.setErrorMsg(response.errorid, 'errorPhoneConfirm')
      }

      this.pnumberVerification.smsTimerExpired = false
      this.pnumberVerification.taskid = 0
      this.pnumberVerification.smsExpireAt = 0
      this.pnumberVerification.isSentCode = false
      this.pnumberVerification.pnumber = ''

      this.$notify({ type: 'success', text: 'Номер телефона успешно привязан' })
      this.$store.dispatch(types.CHECK_TOKEN)
    },
    async verifyTgCode() {
      const token = this.userToken
      const tgnick = this.tgVerification.tgnick.replace(/[^0-9a-zA-Z_]+/g, '')
      const taskid = this.tgVerification.taskid
      const code = this.tgVerification.code

      const { data: response } = await verifyTgCode({ token, tgnick, taskid, code })

      if (response.status !== 1) {
        return this.setErrorMsg(response.errorid, 'errorTgConfirm')
      }

      this.tgVerification.smsTimerExpired = false
      this.tgVerification.taskid = 0
      this.tgVerification.smsExpireAt = 0
      this.tgVerification.isSentCode = false
      this.tgVerification.tgnick = ''

      this.$notify({ type: 'success', text: 'Telegram аккаунт успешно привязан' })
      this.$store.dispatch(types.CHECK_TOKEN)
    },
    sendCodeExpireHandler() {
      // скрыть таймер и показать кнопку отправки заново
      this.pnumberVerification.smsTimerExpired = true
    },
    sendTgCodeExpireHandler() {
      this.tgVerification.smsTimerExpired = true
    },
    async inVacation() {
      if (!window.confirm('Вы уверены? Преждевременный выход из отпуска является платной услугой')) {
        return;
      }

      const { data } = await inVacation({ token: this.userToken })
      if (data.status == 1) {
        this.$notify({ type: 'success', text: 'Вы успешно ушли в отпуск на 14 дней!' })
      } else {
        if (data.errorid == 14) {
          this.$notify({ type: 'error', text: 'Не удалось уйти в отпуск, т.к. вы уже в отпуске.' })
          return;
        }

        if (data.errorid == 15) {
          this.$notify({ type: 'error', text: 'Не удалось уйти в отпуск, т.к. у вас есть летящие в данный момент флоты.' })
          return;
        }

        this.$notify({ type: 'error', text: 'Не удалось уйти в отпуск :(' })
      }
    }
  }
  // beforeMount() {
  //   if (!this.$store.getters.isAuth) {
  //     return this.$router.push({ path: '/login' })
  //   }
  // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss" scoped>
button[disabled] {
  background: #ccc;
}
.recovery-page {
  margin-top: 150px;
}
.wrapper {
  background: rgb(72,82,83);
  background: linear-gradient(90deg, rgba(72,82,83,1) 41%, rgba(108,116,117,0) 100%);
  border: 1px solid #717E81;
  padding: 80px 50px 20px 50px;
  position: relative;

  &:after {
    width: 97px;
    height: 118px;
    position: absolute;
    top: -48px;
    left: 50%;
    margin-left: -49px;
    background: url("~@/assets/login_logo.png") no-repeat;
    background-size: contain;
    display: block;
    content: '';
  }
}

.change-lang {
  position: absolute;
  right: 10px;
  top: 10px;

  select {
    color: #FFF;
    background: #191919;
    padding: 3px 5px
  }
}

.success-msg p {
  background: inherit;
  color: #75ce75;
  padding: 0;
}

h1 {
  font-family: "Russo One", Arial, Helvetica, sans-serif;
  font-size: 1.7rem;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

h2 {
  margin-bottom: 20px;

  a {
    font-weight: normal;
    font-size: 1rem;
  }
}

h3 {
  text-align: left;
  margin-bottom: 10px;
}

form {
  margin-bottom: 15px;
}
p {
  margin-bottom: 15px;
  display: block;
}
p.before {
  margin-top: 0px;
  margin-bottom: 16px;
}

form button {
  //margin-left: 2px;
  display: block;
}

.soc-btns {
  a {
    text-decoration: none;
  }
}

.soc-btn {
  padding: 21px;
  padding-left: 80px;
  margin-bottom: 16px;
  position: relative;
  box-shadow: 0px 0px 5px #000;

  &:before {
    box-shadow: 0px 0px 5px #000;
    content: '';
    display: block;
    width: 63px;
    height: 63px;
    top: 0;
    left: 0;
    position: absolute;
  }

  &-fb {
    background: #5374BA;

    &:before {      
      background: url("~@/assets/fb.png") no-repeat;
    }
  }

  &-google {
    background: #659AF3;

    &:before {
      background: url("~@/assets/google.png") no-repeat;
    }
  }

  &-vk {
    background: #527DAE;

    &:before {
      background: url("~@/assets/vk.png") no-repeat;
    }
  }

  span {
    font-weight: bold;
  }
}
</style>
