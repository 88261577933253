import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './locales/index'

Vue.use(VueI18n)

export default new VueI18n({
    locale: 'ru',
    fallbackLocale: 'en',
    messages,
    pluralizationRules: {
        'ru': function (choice, choicesLength) {
            if (choice === 0) {
                return 1;
            }
    
            const teen = choice > 10 && choice < 20;
            const endsWithOne = choice % 10 === 1;
    
            if (choicesLength < 4) {
                return (!teen && endsWithOne) ? 1 : 2;
            }

            if (!teen && endsWithOne) {
                return 1;
            }
            if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
                return 2;
            }
    
            return (choicesLength < 4) ? 2 : 3;

           // if (choice === 0) {
            //     return 2
            // }
 
            // const endsWith = choice % 10
            // const teen = choice > 10 && choice < 20
            // if (!teen) {
            //     if (endsWith === 1) return 0
            //     if (endsWith > 1 && endsWith < 5) return 1
            // }

            // return 2
        }
    }
})
