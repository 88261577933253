<template>
  <div class="row center-xs middle-xs register-page">
    <div class="col-xs-12 col-md-10 col-lg-6 wrapper">
      <div class="change-lang">
        <select @change="changeLocale">
          <option value="ru">RU</option>
          <option value="en">EN</option>
        </select>
      </div>
      <h1>{{ $t("registerPage.title") }}</h1>
      <div class="success-msg" v-if="successResult">
        <p>Аккаунт успешно создан. На вашу почту отправлена ссылка для подтверждения аккаунта. Через 3 сек. вы будете перенаправлены на страницу авторизации</p>
      </div>
      <form @submit.prevent="submitHandler" autocomplete="off">
      <div class="row">
        <div class="col-xs-12 col-md-6 text-left">
          <input class="input" type="email" name="email" v-model="email" @focus="errors.email = ''" @blur="checkEmail" :placeholder="this.$t('registerPage.email')" autocomplete="off">
          <div class="sub-error" :style="{ visibility: errors.email ? 'visible' : 'hidden' }">{{ errors.email || 'no error' }}</div>
          <input class="input" type="text" name="name" v-model="login" @focus="errors.name = ''" @blur="checkLogin" :placeholder="this.$t('registerPage.nick')" autocomplete="off" minlength="5" maxlength="32" required>
          <div class="sub-error" :style="{ visibility: errors.name ? 'visible' : 'hidden' }">{{ errors.name || 'no error' }}</div>
        </div>
        <div class="col-xs-12 col-md-6 text-left">
          <div class="input-group">
            <input class="input" type="password" name="password" v-model="password" @focus="errors.pass = ''" @blur="checkPassword" :placeholder="this.$t('registerPage.pass')" minlength="8" required>
            <span @click="showPass($event)">
              <img v-if="!isShowPassword" src="~@/assets/view.svg" alt="">
              <img v-else src="~@/assets/hide.svg" alt="">
            </span>
          </div>
          <div class="sub-error" :style="{ visibility: errors.pass ? 'visible' : 'hidden' }">{{ errors.pass || 'no error' }}</div>
          <div class="input-group">
            <input class="input" type="password" name="confirm_password" v-model="confirmPassword" @focus="errors.pass2 = ''" @blur="checkConfirmPassword" :placeholder="this.$t('registerPage.rptPass')" minlength="8" required>
            <span @click="showPass($event)">
              <img v-if="!isShowPassword" src="~@/assets/view.svg" alt="">
              <img v-else src="~@/assets/hide.svg" alt="">
            </span>
          </div>
          <div class="sub-error" :style="{ visibility: errors.pass2 ? 'visible' : 'hidden' }">{{ errors.pass2 || 'no error' }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-6 text-left">
          <div class="row error-msg" v-if="error">
            <div class="col-xs-12">
              <p>{{ error }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <input type="checkbox" checked id="rules-check">
              <label class="checkbox" for="rules-check" v-html="$t('registerPage.agreeRules')"></label>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <input type="checkbox" checked id="promo-check">
              <label class="checkbox" for="promo-check">{{ $t("registerPage.agreePromo") }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <div 
                class="g-recaptcha" 
                :data-sitekey="recaptchaPublicKey" 
                data-theme="dark"
                style="transform:scale(0.77);-webkit-transform:scale(0.77);transform-origin:0 0;-webkit-transform-origin:0 0;" >Loading captcha...</div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <button class="btn full-width" type="submit">{{ $t("registerPage.continue") }}</button>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 text-left">
          <div class="soc-btns">
            <p>{{ $t("loginPage.signSoc") }}</p>

            <div>
              <!-- <a @click="socialAuth('fb')">
                <img src="@/assets/fb.png" alt="">
              </a> -->

              <a @click="socialAuth('google')" class="center">
                <img src="@/assets/google.png" alt="">
              </a>

              <a @click="socialAuth('vk')">
                <img src="@/assets/vk.png" alt="">
              </a>
            </div>
          </div>
        </div>
      </div>
      </form>
    </div>
  </div>
</template>

<script>
import { register } from '../lib/api'
import { fdToObj, reachGoal } from '../lib/utils'
import config from '../config'

export default {
  name: 'RegisterPage',
  data() {
    return {
      email: '',
      login: '',
      password: "",
      confirmPassword: "",
      error: "",
      recaptchaPublicKey: config.RECAPTCHA_PUBLIC,
      successResult: false,
      isShowPassword: false,
      errors: {
        email: '',
        name: '',
        pass: '',
        pass2: ''
      }
    }
  },
  mounted() {
      try {
          window.grecaptcha.render(document.querySelector('.g-recaptcha'))
      } catch (err) {
          window.console.error(err)
      }
  },
  methods: {
    checkPasswords() {
      if (this.password && this.confirmPassword && this.password === this.confirmPassword) {
        return true
      }
      
      return false
    },
    showPass(ev) {
      const trg = ev.target
      const prevField = trg.parentElement.parentElement.parentElement.querySelectorAll('input')
      console.log(prevField)

      prevField.forEach(el => {
        if (el.type === 'password') {
          el.type = 'text'
        } else {
          el.type = 'password'
        }
      })

      this.isShowPassword = !this.isShowPassword
    },
    checkEmail() {
      const isCorrect = this.email.length > 0 && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)

      if (isCorrect) return true

      this.errors.email = 'Проверьте корректность email'
      return false
    },
    checkLogin(login) {
      const isCorrect = this.login.length > 4 && 
                        this.login.length < 33 &&
                        /^[A-Za-z0-9_]+$/.test(this.login)

      if (isCorrect) return true

      this.errors.name = 'Недопустимое имя: от 5 до 60 латинских букв, цифр и знака _'
      return false
    },
    checkPassword() {
      const passwordLength = this.password.length
      console.log(passwordLength)
      const isCorrect = passwordLength > 7 && passwordLength < 101

      if (isCorrect) return true

      this.errors.pass = 'Длина пароля должна быть от 6 до 100 знаков'
      return false
    },
    checkConfirmPassword() {
      const isCorrect = this.password === this.confirmPassword

      if (isCorrect) return true

      this.errors.pass2 = 'Пароли не совпадают'
      return false
    },
    checkCaptchaCompletion() {
      const captchaTextarea = document.querySelector('.g-recaptcha-response')

      if (captchaTextarea && captchaTextarea.value !== '') return true

      this.setErrorMsg(6)

      return false
    },
    async submitHandler(ev) {
      const form = ev.target

      if (!this.checkEmail(this.email)) {
        this.error = "Email некорректный"
        return false
      }

      if (!this.checkLogin(this.login)) {
        this.error = "Логин некорректный. Можно использовать только буквы, цифры и _"
        return false
      }

      if (!this.checkPasswords()) {
        this.error = "Пароли не совпадают"
        return false
      }

      if (!this.checkCaptchaCompletion()) {
        return false
      }

      this.error = ''

      const formObj = fdToObj(new FormData(form))
      const { data } = await register(formObj)

      try {
        window.grecaptcha.reset()
      } catch (err) {
        window.console.error(err)
      }

      if (data.status !== 1) {
        this.setErrorMsg(data.errorid)
        return;
      }

      form.reset()
      this.successResult = true

      reachGoal(config.ymCounterId, 'createAccount')

      setTimeout(() => {
        this.$router.push('login')
        //this.successResult = false
      }, 3000);
    },
    setErrorMsg(errorid) {
      let errorMsg = ''

      switch (errorid) {
        case 2:
          errorMsg = "Пользователь с такой почтой или ником уже существует"
          break
        case 5:
          errorMsg = "Введенные данные некорректны"
          break
        case 6:
          errorMsg = "Каптча решена неверно"
          break
        case 16:
          return this.errors.email = 'Пользователь с таким email уже существует'
        case 17:
          return this.errors.name = 'Пользователь с таким ником уже существует'
        default:
          errorMsg = "Техническая ошибка. Попробуйте позже"
      }

      this.error = errorMsg
    },
    socialAuth(provider) {
      const providerConfig = config['oauth'][provider]
      const q = providerConfig['q']

      const url = providerConfig['url'] + '?' + Object.keys(q).map(function (i) { return i + '=' + encodeURIComponent(q[i]) }).join('&')

      window.location.href = url
    },
    changeLocale(ev) {
      this.$i18n.locale = ev.target.value
    }
  }
}
</script>

<style scoped lang="scss">
.register-page {
  margin-top: 150px;
}

.wrapper {
  background: rgb(72,82,83);
  background: linear-gradient(90deg, rgba(72,82,83,1) 41%, rgba(108,116,117,0) 100%);
  border: 1px solid #717E81;
  padding: 80px 50px 20px 50px;
  position: relative;

  &:after {
    width: 97px;
    height: 118px;
    position: absolute;
    top: -48px;
    left: 50%;
    margin-left: -49px;
    background: url("~@/assets/login_logo.png") no-repeat;
    background-size: contain;
    display: block;
    content: '';
  }
}

.change-lang {
  position: absolute;
  right: 10px;
  top: 10px;

  select {
    color: #FFF;
    background: #191919;
    padding: 3px 5px
  }
}

h1 {
  font-family: "Russo One", Arial, Helvetica, sans-serif;
  font-size: 1.7rem;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1px;
  margin-bottom: 30px;
}

form {
  margin-bottom: 15px;
}
p {
  margin-bottom: 15px;
  display: block;
}
p.before {
  margin-top: 0px;
  margin-bottom: 16px;
}

form button {
  margin-left: 2px;
}

label {
  margin-bottom: 10px;
}

.btn {
  margin-bottom: 10px;
}

.soc-btns {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100%;

  a {
    text-decoration: none;
    cursor: pointer;

    &.middle {
      margin: 0 10px;
    }
  }

  div {
    a {
      display: inline-block;
      margin: 0 10px;

      img {
        max-width: 40px;
      }
    }
  }
}

.input-group {
  position: relative;

  input {
    padding-right: 3rem;
  }
  
  span {
    display: block;
    position: absolute;
    top: 1rem;
    right: 10px;
    font-size: 1.5rem;
    cursor: pointer;
    user-select: none;
    
    img {
      display: inline-block;
      height: 1.5rem;
      vertical-align: middle;
    }
  }
}

form {
  .input {
    margin-bottom: 0;
  }

  .sub-error {
    margin-bottom: 1rem;
    color: #ff5b5b;
  }
}
</style>
