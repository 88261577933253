<template>
  <div class="container">
    <div class="row center-xs middle-xs recovery-page">
      <div class="col-xs-12">
        <h1>Симулятор боя</h1>
        <div class="row">
          <div class="col-xs-12" v-if="fightingResult">
            <textarea v-if="fightingResult" v-model="fightingResult" style="width:100%; min-height: 500px"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-5">
            <h2>Атакующие</h2>
            <div class="fleet-item text-left">
              <div class="row">
                <div class="col-xs-12">
                  <h3>Технологии</h3>
                  <div v-for="(v, k) in base.attacker.technology_levels" :key="'at_tech_' + k">
                    <label for="">{{ getName(k) }}</label>
                    <input type="number" v-model.number="base.attacker.technology_levels[k]">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12">
                  <h3>Флот</h3>
                  <div v-for="(v, k) in base.attacker.spaceships" :key="'at_ship_' + k">
                    <label for="">{{ getName(k) }}</label>
                    <input type="number" v-model.number="base.attacker.spaceships[k]">
                  </div>
                </div>
              </div>
            </div>

            <div class="fleet-item text-left" v-for="(a, i) in this.base.allied" :Key="a.id">
              <div class="row">
                <div class="col-xs-12">
                  <h3>САБ {{ a.id }}</h3>
                  <h3>Технологии</h3>
                  <div v-for="(v, k) in base.allied[i].technology_levels" :key="'at_tech_' + i + '_' + k">
                    <label for="">{{ getName(k) }}</label>
                    <input type="number" v-model.number="base.allied[i].technology_levels[k]">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12">
                  <h3>Флот</h3>
                  <div v-for="(v, k) in base.allied[i].spaceships" :key="'at_ship_' + i + '_' + k">
                    <label for="">{{ getName(k) }}</label>
                    <input type="number" v-model.number="base.allied[i].spaceships[k]">
                  </div>
                </div>
              </div>
            </div>

            <button @click="addSub()">Добавить САБ</button>
          </div>
          <div class="col-xs-2 btns">
            <button @click="connect()">Реконнект!</button>
            <button @click="fill(true)">Заполнить рандомно!</button>
            <button @click="fight()">Бой!</button>
          </div>
          <div class="col-xs-5">
            <h2>Обороняющийся</h2>
            <div class="fleet-item text-left">
              <div class="row">
                <div class="col-xs-12">
                  <h3>Технологии</h3>
                  <div v-for="(v, k) in base.defender.technology_levels" :key="'def_tech_' + k">
                    <label for="">{{ getName(k) }}</label>
                    <input type="number" v-model.number="base.defender.technology_levels[k]">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12">
                  <h3>Флот</h3>
                  <div v-for="(v, k) in base.defender.spaceships" :key="'def_ship_' + k">
                    <label for="">{{ getName(k) }}</label>
                    <input type="number" v-model.number="base.defender.spaceships[k]">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12">
                  <h3>Оборона</h3>
                  <div v-for="(v, k) in base.defender.buildings" :key="'def_def_' + k">
                    <label for="">{{ getName(k) }}</label>
                    <input type="number" v-model.number="base.defender.buildings[k]">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SimulatorPage',
  data: () => {
    return {
      defense: {
        missile_block: 'Ракетный блок',
        infrared_laser: 'Инфракрасный лазер',
        ultraviolet_laser: 'Ультрафиолетовый лазер',
        graviton_weapon: 'Гравитонное орудие',
        photon_cannon: 'Фотонная пушка',
        lepton_cannon: 'Лептонная пушка',
        small_energy_shield: 'Малый энергокупол',
        big_energy_shield: 'Большой энергокупол'
      },
      spaceships: {
        shuttle: 'Шаттл',
        fighter: 'Истребитель',
        transport: 'Транспорт',
        energodrone: 'Энергодрон',
        attacker: 'Штурмовик',
        frigate: 'Фрегат',
        galaction: 'Галактион',
        destroyer: 'Разрушитель',
        bombardier: 'Бомбардир',
        colossus: 'Колосс',
        scout: 'Разведзонд',
        collector: 'Коллектор',
        pioneer: 'Первопроходец',
        corvette: 'Корвет'
      },
      technologies: {
        planet_scanning: 'Планетарное сканирование',
        navigation: 'Навигация',
        armament: 'Вооружения',
        ship_protection: 'Защита кораблей',
        energy_shields: 'Энергетические поля',
        energetics: 'Энергетика',
        subspace_travel: 'Перемещение в подпространстве',
        baryonic_engine: 'Барионный двигатель',
        annihilation_engine: 'Аннигиляционный двигатель',
        subspace_engine: 'Подпространственный двигатель',
        military_lasers: 'Боевые лазеры',
        photonic_weapon: 'Фотонное оружие',
        leptonic_weapon: 'Лептонное оружие',
        tachyon_scan: 'Тахионное сканирование',
        planet_exploration: 'Освоение планет',
        vibrotron: 'Вибротрон'
      },

      base: {
        attacker: {
          technology_levels: {},
          spaceships: {}
        },
        defender: {
          technology_levels: {},
          buildings: {},
          spaceships: {}
        },
        allied: []
      },
      socket: null,
      msgId: 0,
      rndId: 0,
      fightingResult: ''
    }
  },
  created() {
    this.fill()

    this.rndId = Math.round(Math.random() * 10000)

    this.connect()
  },
  methods: {
    connect() {
      this.socket = new WebSocket('wss://srv2.warforgalaxy.com:9982')
    
      this.socket.onopen = () => {
        this.$notify({ type: 'success', text: 'Соединение с сервером установлено' })
        this.auth()
      }

      this.socket.onmessage = ev => {
        console.log('Пришло:')
        console.log(ev.data)
        console.log('------------------------')
        this.$notify({ type: 'info', text: 'Получен ответ от сервера' })

        try {
          const json = JSON.parse(ev.data)
          if (json.result.rounds === undefined) return;
          this.fightingResult = this.buildReport(json.result)
        } catch (err) {
          console.error(err)
        }
      }

      this.socket.onerror = (err) => {
        console.error(err)
        this.$notify({ type: 'error', text: 'Ошибка соедиения' })
      }
    },
    fill(isRnd = false) {
      if(isRnd) {
        this.base.attacker.technology_levels = this.getEmptyData('technologies', true)
        this.base.attacker.spaceships = this.getEmptyData('spaceships', true)

        this.base.defender.technology_levels = this.getEmptyData('technologies', true)
        this.base.defender.buildings = this.getEmptyData('defense', true)
        this.base.defender.spaceships = this.getEmptyData('spaceships', true)
      } else {
        this.base.attacker.technology_levels = this.getEmptyData('technologies')
        this.base.attacker.spaceships = this.getEmptyData('spaceships')

        this.base.defender.technology_levels = this.getEmptyData('technologies')
        this.base.defender.buildings = this.getEmptyData('defense')
        this.base.defender.spaceships = this.getEmptyData('spaceships')
      }
    },
    isSpaceship(techName) {
      return this.spaceships[techName] !== undefined;
    },
    createAllied(id, technology_levels, spaceships) {
      return { id, technology_levels, spaceships }
    },
    addSub() {
      this.base.allied.push({
        id: this.getNewAlliedId(),
        technology_levels: this.getEmptyData('technologies'),
        spaceships: this.getEmptyData('spaceships')
      })
    },
    getNewAlliedId() {
      return this.base.allied.length + 1
    },
    randomInt(min, max) {
      return Math.round(Math.random() * (max - min) + min);
    },
    getEmptyData(key, isRnd = false, min = 1, max = 11) {
      return Object.fromEntries(Object.keys(this[key]).map(i => [i, isRnd ? this.randomInt(min, max) : 0]))
    },
    getName(key) {
      return this.defense[key] || this.technologies[key] || this.spaceships[key] || key
    },
    auth() {
      const token = window.localStorage.getItem('glxtoken')
      const m = {"id": this.getMessageId(),"jsonrpc":"2.0","method":"auth.token","params":{"token": token}}

      this.socket.send(JSON.stringify(m))

      console.log('Ушло:')
      console.log(JSON.stringify(m))
      console.log('------------------------')
    },
    fight() {
      const d = Object.assign({}, this.base);
      d['attackers'] = [ d.attacker ]
      //delete(d['attacker'])
      
      const m = {"id": this.getMessageId() ,"jsonrpc":"2.0","method":"api.battle.simulate","params": d}
      this.socket.send(JSON.stringify(m))

      console.log('Ушло:')
      console.log(JSON.stringify(m))
      console.log('------------------------')
    },
    getMessageId() {
      return 'simulator_'+ this.rndId + '_' + ++this.msgId
    },
    buildReport(reportJson) {
      let r = ''
      for (let round of reportJson.rounds) {
        r += '----------------------------------------------------------------------\n'
        r += `Раунд ${round.round + 1}\n`
        r += '-----------------------------------\n\n'
        r += 'Атакующий: ???? (оружие: ???%, броня: ???%, щиты: ???%)\n\n'
        
        // корабли атаки
        for (let atSquads of round.attacker.squads_before) {
          for (let atSquadsUnit of atSquads.units) {
            r += `Корабль: ${this.getName(atSquadsUnit.type)} (${atSquadsUnit.amount}) Скорость: (${atSquadsUnit.speed})\n`
          }
        }
        r += '\n-----------------------------------\n\n'
        r += 'Обороняющийся: ??? (оружие: ???%, броня: ???%, щиты: ???%)\n\n'

        // корабли обороны
        if (round.defender.squads_before[0]) {
          for (let dfSquadsUnit of round.defender.squads_before[0].units) {
            r += `${this.isSpaceship(dfSquadsUnit.type) ? 'Корабль' : 'Оборона'}: ${this.getName(dfSquadsUnit.type)} (${dfSquadsUnit.amount}) ${ this.isSpaceship(dfSquadsUnit.type) ? `Скорость: (${dfSquadsUnit.speed})` : ''}\n`
          }
        }

        if (round.defender.squads_before[1]) {
          for (let dfSquadsUnit of round.defender.squads_before[1].units) {
            r += `${this.isSpaceship(dfSquadsUnit.type) ? 'Корабль' : 'Оборона'}: ${this.getName(dfSquadsUnit.type)} (${dfSquadsUnit.amount}) ${ this.isSpaceship(dfSquadsUnit.type) ? `Скорость: (${dfSquadsUnit.speed})` : ''}\n`
          }
        }

        r += '\n-----------------------------------\n'
        r += `Атакующий делает выстрелы общей мощностью ${round.defender.received_damage}. Щиты обороняющегося поглощают ${round.defender.absorb_damage} выстрелов.\n`
        r += `Обороняющийся делает выстрелы общей мощностью ${round.attacker.received_damage}. Щиты атакующего поглощают ${round.attacker.absorb_damage} выстрелов.\n`
      }

      r += '----------------------------------------------------------------------\n'
      r += `Побеждает: ${( reportJson.status == 'attacker' ? 'Атакающий' : ( reportJson.status == 'defender' ? 'Обороняющийся' : 'Ничья' ) )}`

      return r
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss" scoped>
.btns {
  button {
    margin-bottom: 20px;
  }
}
// h3 {
  
// }

// label {
//   // display: block;
// }

label ~ input {
  margin: 0 0 1px 5px;
  width: 50px;
  padding: 0px 1px;
}

.fleet-item {
  padding: 5px;
  background: #383838;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 12px;
}

button[disabled] {
  background: #ccc;
}
.recovery-page {
  margin-top: 150px;
}
.wrapper {
  background: rgb(72,82,83);
  background: linear-gradient(90deg, rgba(72,82,83,1) 41%, rgba(108,116,117,0) 100%);
  border: 1px solid #717E81;
  padding: 80px 50px 20px 50px;
  position: relative;

  &:after {
    width: 97px;
    height: 118px;
    position: absolute;
    top: -48px;
    left: 50%;
    margin-left: -49px;
    background: url("~@/assets/login_logo.png") no-repeat;
    background-size: contain;
    display: block;
    content: '';
  }
}

.change-lang {
  position: absolute;
  right: 10px;
  top: 10px;

  select {
    color: #FFF;
    background: #191919;
    padding: 3px 5px
  }
}

.success-msg p {
  background: inherit;
  color: #75ce75;
  padding: 0;
}

h1 {
  font-family: "Russo One", Arial, Helvetica, sans-serif;
  font-size: 1.7rem;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

h2 {
  margin-bottom: 20px;

  a {
    font-weight: normal;
    font-size: 1rem;
  }
}

h3 {
  text-align: left;
  margin-bottom: 2px;
  border-bottom: 1px solid #fff;
}

form {
  margin-bottom: 15px;
}
p {
  margin-bottom: 15px;
  display: block;
}
p.before {
  margin-top: 0px;
  margin-bottom: 16px;
}

form button {
  //margin-left: 2px;
  display: block;
}

.soc-btns {
  a {
    text-decoration: none;
  }
}

.soc-btn {
  padding: 21px;
  padding-left: 80px;
  margin-bottom: 16px;
  position: relative;
  box-shadow: 0px 0px 5px #000;

  &:before {
    box-shadow: 0px 0px 5px #000;
    content: '';
    display: block;
    width: 63px;
    height: 63px;
    top: 0;
    left: 0;
    position: absolute;
  }

  &-fb {
    background: #5374BA;

    &:before {      
      background: url("~@/assets/fb.png") no-repeat;
    }
  }

  &-google {
    background: #659AF3;

    &:before {
      background: url("~@/assets/google.png") no-repeat;
    }
  }

  &-vk {
    background: #527DAE;

    &:before {
      background: url("~@/assets/vk.png") no-repeat;
    }
  }

  span {
    font-weight: bold;
  }
}
</style>
