<template>
    <div></div>
</template>

<script>
import config from '../config'
import { revokeToken } from '../lib/api'

export default {
  name: 'LogoutPage',
  data: () => {
    return {}
  },
  async created() {
      await revokeToken({ token: this.userToken, revoke: this.userToken })

      window.localStorage.removeItem(config.LS_TOKEN_NAME)
      document.cookie = `${config.LS_TOKEN_NAME}=; expires=-1; path=/; domain=.warforgalaxy.com`

      window.location.href = '/'
  },
  computed: {
    userToken() {
      return this.$store.state.user.token
    }
  }
}
</script>
