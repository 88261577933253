import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import store from './store'
import Notifications from 'vue-notification'
import VueModal from 'vue-js-modal'

Vue.config.productionTip = false
Vue.use(Notifications)
Vue.use(VueModal)

const app = new Vue({
  store,
  router,
  i18n,
  data: { loading: true },
  render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
  app.loading = true
  setTimeout(() => next(), 100)
  //next()
})

router.afterEach(() => {
  
  setTimeout(() => app.loading = false, 300)
})